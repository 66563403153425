import React, { Fragment, useState } from "react";
import { integrationTypes } from "src/constants/settings";
import ModalLayout from "src/layouts/ModalLayout";
import { DeleteAccount } from "src/services/settings/integrations";
import { getCustomerID, parseURL } from "src/utils/general";

const TestCredential = ({ account }: { account: any }) => {
  const parsed = parseURL();
  const customerID = getCustomerID();

  const accountID = account.integration_id;

  const [show, setShow] = useState<boolean>(false);
  const [deleteAccountID, setDeleteAccountID] = useState<boolean>(false);

  const deleteAccount = DeleteAccount(customerID);

  const handleOnClose = () => setShow(false);
  const handleOnCloseDelete = () => setDeleteAccountID(false);

  const Integration = integrationTypes[String(parsed.integration)];

  return (
    <>
      <button
        className="absolute right-5 top-1/4 blue-text-button"
        onClick={() => setShow(true)}
      >
        Test Credential
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <header className="flex items-center gap-4">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Test Credential
          </h3>
          <p className="flex items-center gap-2 px-2 py-1 dark:bg-gray-700 ronuded">
            <span className="text-b2-reg">Account #</span>
            <span className="text-b2-semi dark:text-[#4F71E3]">
              {account.customer_cloud_id}
            </span>
          </p>
          <button
            className="place-self-end hard-delete-button"
            onClick={() => setDeleteAccountID(true)}
          >
            Delete
          </button>
          <>
            <ModalLayout
              showModal={deleteAccountID}
              onClose={handleOnCloseDelete}
            >
              <section className="grid gap-4">
                <h2 className="text-subt1-semi">Remove Account</h2>
                <h4 className="text-b1-reg">
                  Are you sure you want to remove this account?
                </h4>
                <article className="flex items-center place-content-end gap-4">
                  <button
                    className="black-button"
                    onClick={handleOnCloseDelete}
                  >
                    Cancel
                  </button>
                  <button
                    className="red-button"
                    onClick={() => {
                      deleteAccount.mutate({
                        accountID,
                      });
                      handleOnClose();
                    }}
                  >
                    Remove
                  </button>
                </article>
              </section>
            </ModalLayout>
          </>
        </header>
        {Integration && (
          <Integration
            setShowIntegrationDetails={setShow}
            selectedAccountID={accountID}
          />
        )}
      </ModalLayout>
    </>
  );
};

export default TestCredential;
