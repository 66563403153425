/* eslint-disable no-global-assign */
import {
  Node,
  Position,
  MarkerType,
  ReactFlowInstance,
  getTransformForBounds,
} from "reactflow";
import { KeyStringVal } from "src/types/general";
import ELK from "elkjs/lib/elk.bundled.js";

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
const getNodeIntersection = (intersectionNode: any, targetNode: any) => {
  // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
  const {
    width: intersectionNodeWidth,
    height: intersectionNodeHeight,
    positionAbsolute: intersectionNodePosition,
  } = intersectionNode;
  const targetPosition = targetNode.positionAbsolute;

  const w = intersectionNodeWidth / 2;
  const h = intersectionNodeHeight / 2;

  const x2 = intersectionNodePosition.x + w;
  const y2 = intersectionNodePosition.y + h;
  const x1 = targetPosition.x + w;
  const y1 = targetPosition.y + h;

  const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
  const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
  const xx3 = a * xx1;
  const yy3 = a * yy1;
  const x = w * (xx3 + yy3) + x2;
  const y = h * (-xx3 + yy3) + y2;

  return { x, y };
};

// returns the position (top,right,bottom or right) passed node compared to the intersection point
const getEdgePosition = (node: any, intersectionPoint: any) => {
  const n = { ...node.positionAbsolute, ...node };
  const nx = Math.round(n.x);
  const ny = Math.round(n.y);
  const px = Math.round(intersectionPoint.x);
  const py = Math.round(intersectionPoint.y);

  if (px <= nx + 1) {
    return Position.Left;
  }
  if (px >= nx + n.width - 1) {
    return Position.Right;
  }
  if (py <= ny + 1) {
    return Position.Top;
  }
  if (py >= n.y + n.height - 1) {
    return Position.Bottom;
  }

  return Position.Top;
};

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export const getEdgeParams = (source: any, target: any) => {
  const sourceIntersectionPoint = getNodeIntersection(source, target);
  const targetIntersectionPoint = getNodeIntersection(target, source);

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
  const targetPos = getEdgePosition(target, targetIntersectionPoint);

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos,
  };
};

// re-centers the react flow graph according to viewport
export const onInit = (
  store: any,
  nodes: Node[],
  setMinZoom: (minZoom: number) => void,
  reactFlowInstance: ReactFlowInstance
) => {
  const { width, height } = store.getState();
  let heights: number[] = [];
  let widths: number[] = [];

  nodes.forEach((node: Node) => {
    heights = [...heights, node.position.y];
    widths = [...widths, node.position.x];
  });

  const extra = nodes.length === 1 ? 700 : 400;
  const maxHeight = Math.max(...heights) + extra;
  const maxWidth = Math.max(...widths) + extra;

  const transformBounds = getTransformForBounds(
    { height: maxHeight, width: maxWidth, x: 0, y: 0 },
    width,
    height,
    0,
    1
  );

  setMinZoom(transformBounds[2]);
  reactFlowInstance.setViewport({
    x: transformBounds[0],
    y: transformBounds[1],
    zoom: transformBounds[2],
  });
};

export const createMappingNodes = (
  mapping: any,
  setNodes: any,
  setEdges: any,
  store: any,
  setMinZoom: (minZoom: number) => void,
  reactFlowInstance: ReactFlowInstance,
  filters: any,
  documents: string[]
) => {
  const filtered =
    filters.length > 0
      ? mapping.data.filter(
          (section: KeyStringVal) =>
            !filters.includes(section.policy_name || section.framework_name)
        )
      : mapping.data;
  const n = filtered.length;
  const tempNodes = [];
  const tempEdges = [] as any;
  const xExtra = filtered.length > 1 ? 200 * (n / 3) : -500;
  const yExtra = filtered.length > 2 ? 200 * (n / 3) : -300;
  const center = {
    x: window.innerWidth / 2 + xExtra,
    y: window.innerHeight / 2 + yExtra,
  };

  tempNodes.push({
    id: "source",
    type: "mapping",
    data: {
      id: "source",
      center: true,
      ...sessionStorage,
      extracted_tags:
        sessionStorage.extracted_tags !== ""
          ? sessionStorage.extracted_tags?.split(",")
          : [],
      secondary_tags:
        sessionStorage.secondary_tags !== ""
          ? sessionStorage.secondary_tags?.split(",")
          : [],
    },
    position: center,
  });

  filtered.forEach((section: any, i: number) => {
    const degrees = i * (360 / n);
    const radians = degrees * (Math.PI / 180);
    const x = 400 * (n < 6 ? 2.5 : n / 3) * Math.cos(radians) + center.x;
    const y = 300 * (n < 6 ? 2.5 : n / 3) * Math.sin(radians) + center.y;

    const targetID = section.generated_id;
    tempNodes.push({
      id: targetID,
      type: "mapping",
      data: {
        id: targetID,
        center: false,
        ...section,
        document_type: section.policy_name ? "policies" : "frameworks",
        document_name: section.policy_name || section.framework_name,
      },
      position: { x, y },
    });

    tempEdges.push({
      id: `edge-${targetID}`,
      target: targetID,
      source: "source",
      type: "mapping",
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
        color: "#7894B0",
      },
    });
  });

  onInit(store, tempNodes, setMinZoom, reactFlowInstance);
  setNodes(tempNodes);
  setEdges(tempEdges);
};

export const handleClickMapping = (
  documentType: string,
  documentName: string,
  documentID: string | undefined,
  selectedTab: string | undefined,
  subsection: any,
  mappingType: string,
  mappedSections: number,
  auditID?: string,
  controlID?: string
) => {
  sessionStorage.mapping_type = mappingType || "";
  sessionStorage.document_type = documentType || "";
  sessionStorage.document_tab = selectedTab || "";
  sessionStorage.document_name = documentName || "";
  sessionStorage.document_id = documentID || "";
  sessionStorage.control_id = controlID || "";
  sessionStorage.audit_id = auditID || "";
  sessionStorage.generated_id = subsection.generated_id || "";
  sessionStorage.section_type = subsection.section_type || "";
  sessionStorage.section_title = subsection.section_title || "";
  sessionStorage.sub_section_title = subsection.sub_section_title || "";
  sessionStorage.sub_section_id = subsection.sub_section_id || "";
  sessionStorage.control_criteria = subsection.control_criteria || "";
  sessionStorage.sub_control_description =
    subsection.sub_control_description || "";
  sessionStorage.content = subsection.content || "";
  sessionStorage.control_blueprint_description =
    subsection.control_blueprint?.description || "";
  sessionStorage.user_email = subsection.metadata.user_email || "";
  sessionStorage.extracted_tags = subsection.extracted_tags || "";
  sessionStorage.secondary_tags = subsection.secondary_tags || "";
  if (mappedSections === 0) sessionStorage.open_new_mapping = "true";
};

// resets the nodes/edges positions according to directed graph layout
export const getELKLayoutedDRLElements = async (
  nodes: any,
  edges: any,
  width: number,
  height: number,
  options = {}
) => {
  const elk = new ELK();

  const isHorizontal = options?.["elk.direction"] === "RIGHT";
  const graph = {
    id: "root",
    layoutOptions: options,
    children: nodes.map((node: any) => ({
      ...node,
      // Adjust the target and source handle positions based on the layout
      // direction.
      targetPosition: isHorizontal ? "left" : "top",
      sourcePosition: isHorizontal ? "right" : "bottom",

      // Hardcode a width and height for elk to use when layouting.
      width: width,
      height: height,
    })),
    edges: edges,
  };

  try {
    const layoutedGraph = await elk.layout(graph);
    return {
      nodes: layoutedGraph.children?.map((node_1: any) => ({
        ...node_1,
        // React Flow expects a position property on the node instead of `x`
        // and `y` fields.
        position: {
          x: node_1.node_type === "DRL_ITEM" ? node_1.x : node_1.x * 0.4,
          y: node_1.y,
        },
      })),
      edges: layoutedGraph.edges,
    };
  } catch (message) {
    throw new Error(String(message));
  }
};

export const renderDRLGraph = (
  nodes: any,
  edges: any,
  setNodes: (nodes: any) => void,
  setEdges: (edges: any) => void,
  store: any,
  setMinZoom: (minZoom: number) => void,
  reactFlowInstance: ReactFlowInstance
) => {
  // for each react flow node, store important info in the data object
  const tempNodes = nodes.map((node: any) => {
    return {
      id: node.node_id || "",
      type: node.node_type,
      data: {
        id: node.node_id || "",
        type: node.node_type,
        ...node,
      },
      position: {
        x: 0,
        y: 0,
      },
    };
  });

  // for each react flow edge, store important info in the data object
  const tempEdges = edges.map((edge: any) => {
    return {
      id: edge.edge_id || "",
      type: "drl_item",
      source: edge.source,
      target: edge.target,
      animated: false,
      data: {
        id: edge.edge_id || "",
        type: "drl_item",
        ...edge,
      },
    };
  });

  // resets the nodes/edges positions according to directed graph layout
  getELKLayoutedDRLElements(tempNodes, tempEdges, 7000, 150, {
    "elk.direction": "RIGHT",
    "elk.algorithm": "layered",
    // "elk.spacing.nodeNode": "0",
  }).then(({ nodes: layoutedNodes, edges: layoutedEdges }: any) => {
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);

    onInit(store, layoutedNodes, setMinZoom, reactFlowInstance);
  });
};
