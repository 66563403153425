import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";

const CategoryInput = ({
  label,
  keyName,
  list,
  inputs,
  setInputs,
  allowUnselect,
  required,
  allowNewValue,
}: {
  label: string;
  keyName: string;
  list: string[];
  inputs: any;
  setInputs: (inputs: any) => void;
  allowUnselect?: boolean;
  required?: boolean;
  allowNewValue?: boolean;
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [newValue, setNewValue] = useState<string>("");
  const [updatedList, setUpdatedList] = useState<string[]>([]);

  const filteredItems = updatedList?.filter((item: string) =>
    item
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(query.toLowerCase().replace(/\s+/g, ""))
  );

  useEffect(() => {
    if (list?.length > 0) setUpdatedList(list);
  }, [list]);

  return (
    <article className="grid content-start text-b2-reg">
      <header className="flex items-center gap-1 justify-self-start">
        <h4>{label}</h4>
        {required && <span className="-mt-2 text-h5 text-red-500">*</span>}
      </header>{" "}
      <article
        onMouseLeave={() => setShowDropdown(false)}
        onClick={() => setShowDropdown(true)}
        className="relative px-4 py-2 w-full h-9 dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 focus:outline-none rounded"
      >
        <article className="flex items-center justify-between gap-2">
          <input
            type="input"
            autoComplete="off"
            spellCheck="false"
            placeholder="Select"
            value={query || inputs[keyName].replaceAll("_", " ")}
            onChange={(e) => {
              if (!showDropdown) setShowDropdown(true);
              setQuery(e.target.value);
            }}
            className="w-full placeholder:normal-case capitalize border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
          <span className="flex items-center pointer-events-none">
            <ChevronDownIcon
              className="w-5 h-5 dark:text-gray-500"
              aria-hidden="true"
            />
          </span>
        </article>
        {showDropdown && (
          <article className="absolute top-9 left-0 grid gap-1 p-1 content-start w-full max-h-36 dark:bg-gray-900 focus:outline-none shadow dark:shadow-black overflow-auto scrollbar rounded-b-sm z-10">
            {allowNewValue && (
              <article className="flex items-cneter gap-2 m-2">
                <textarea
                  value={newValue}
                  placeholder="Add New Value"
                  onChange={(e) => setNewValue(e.target.value)}
                  className="px-4 py-2 w-full h-9 text-b2-reg overflow-hidden border-0 bg-transparent dark:hover:bg-gray-700 duration-100 ring dark:ring-offset-gray-700/50 dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 resize-none rounded"
                />
                <button
                  disabled={!newValue}
                  className="small-blue-button"
                  onClick={() => {
                    setUpdatedList([newValue, ...updatedList]);
                    setNewValue("");
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </article>
            )}
            {filteredItems?.map((item: string) => {
              const selected = inputs[keyName] === item;
              return (
                <button
                  key={item}
                  className={`relative flex items-center gap-2 capitalize px-4 py-1 text-left break-words dark:hover:bg-gray-800 duration-100 ${
                    selected ? "dark:bg-gray-800" : ""
                  } rounded-lg`}
                  onClick={() => {
                    setQuery("");
                    if (allowUnselect && selected)
                      setInputs({ ...inputs, [keyName]: "" });
                    else setInputs({ ...inputs, [keyName]: item });
                  }}
                >
                  {selected && <FontAwesomeIcon icon={faCheck} />}
                  {item.replaceAll("_", " ")}
                </button>
              );
            })}
          </article>
        )}
      </article>
    </article>
  );
};

export default CategoryInput;
