import {
  faEnvelope,
  faLocation,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { attributeColors } from "src/constants/general";
import MessageLayout from "src/layouts/MessageLayout";
import { GetAllUsers } from "src/services/settings/users";
import {
  checkIsContactList,
  checkIsList,
  checkIsUserList,
  checkIsUserObject,
  convertToUTCShortString,
  getCustomerID,
  getEmailFromUserID,
  getTimeLeft,
  isEpoch,
  isValidUUID,
} from "src/utils/general";

const RowValue = ({ row, col }: { row: any; col: string }) => {
  const customerID = getCustomerID();

  const [showMore, setShowMore] = useState<boolean>(false);

  const { data: allUsers } = GetAllUsers(customerID, false);

  const isDate = isEpoch(row[col]);
  const isUser = isValidUUID(row[col]);
  const isUserObject = checkIsUserObject(row[col]);
  const isList = checkIsList(row[col]);
  const isUserList = checkIsUserList(row[col]);
  const isContactList = checkIsContactList(row[col]);
  const content = String(row[col])?.replaceAll("_", " ");
  const longText = content?.length > 100;

  return (
    <section className="break-all">
      {row[col] == null ? (
        "-"
      ) : isDate ? (
        convertToUTCShortString(row[col])
      ) : isUser ? (
        getEmailFromUserID(allUsers, row[col])
      ) : isUserObject ? (
        row[col]?.user_email
      ) : isList ? (
        <ul className="flex flex-col flex-grow list-disc">
          {row[col]?.map((value: string, idx: number) => {
            return (
              <li key={idx}>
                {isValidUUID(value)
                  ? getEmailFromUserID(allUsers, value)
                  : value}
              </li>
            );
          })}
        </ul>
      ) : isUserList ? (
        <article className="flex flex-col flex-grow gap-1">
          {row[`${col}_text`]?.map((user: string) => {
            return <span key={user}>{user}</span>;
          })}
          {row[col]?.map((user: any, idx: number) => {
            let value = "";
            if (typeof user === "string")
              value = getEmailFromUserID(allUsers, user);
            else value = getEmailFromUserID(allUsers, user.user_id);
            return <span key={idx}>{value}</span>;
          })}
        </article>
      ) : isContactList ? (
        <article className="flex flex-col flex-grow gap-4">
          {row[col]?.map((contact: any, idx: number) => {
            const keys = Object.keys(contact);

            return (
              <section key={idx} className="grid content-start gap-1">
                {keys.map((key) => {
                  if (key.includes("id")) return null;

                  return (
                    <article
                      key={key}
                      className="flex items-center gap-2 px-2 py-1 w-max dark:bg-gray-700 rounded"
                    >
                      <FontAwesomeIcon
                        icon={
                          key.includes("name")
                            ? faUser
                            : key.includes("email")
                            ? faEnvelope
                            : key.includes("phone")
                            ? faPhone
                            : faLocation
                        }
                        className="dark:text-blue-500"
                      />
                      <span className="text-b1-semi">{contact[key]}</span>
                    </article>
                  );
                })}
              </section>
            );
          })}
        </article>
      ) : col === "status" && row.estimated_time_left > 0 ? (
        <MessageLayout type="info">
          Check back in {getTimeLeft(row.estimated_time_left)}
        </MessageLayout>
      ) : (
        <p
          className={`text-left ${
            attributeColors[String(content)?.toLowerCase()]
          }`}
        >
          {content?.slice(0, showMore || !longText ? content.length : 100)}{" "}
          {longText && (
            <p>
              {!showMore && <span>...</span>}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMore(!showMore);
                }}
                className="dark:text-blue-500 dark:hover:text-blue-500/70 duration-100"
              >
                show {showMore ? "less" : "more"}
              </button>{" "}
            </p>
          )}
        </p>
      )}
    </section>
  );
};

export default RowValue;
