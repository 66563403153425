import { useQuery, useMutation } from "react-query";
import { queryClient } from "src/App";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";

export const questionBankPrefix = `grc/assessments/question-banks`;

export const GetQuestionBanks = (pageNumber?: number) =>
  useQuery<any, unknown, any, (string | number | undefined)[]>(
    ["get-question-banks", pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}${questionBankPrefix}/all`,
          {
            ...(pageNumber && {
              page_size: pageSize,
              page_number: pageNumber,
            }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const GetQuestionBankQuestions = (
  questionBankID: string,
  pageNumber?: number
) =>
  useQuery<any, unknown, any, (string | number | undefined)[]>(
    ["get-question-bank-questions", questionBankID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}${questionBankPrefix}/${questionBankID}/questions`,
          {
            ...(pageNumber && {
              page_size: pageSize,
              page_number: pageNumber,
            }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    { enabled: questionBankID !== "" }
  );

export const SubmitQuestionBank = () =>
  useMutation<any, unknown, any, string>(
    async ({
      formData,
      signal,
    }: {
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}${questionBankPrefix}/new`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-drafts"]);
      },
    }
  );

export const RemoveQuestionBank = () =>
  useMutation<any, unknown, any, string>(
    async ({
      questionBankID,
      signal,
    }: {
      questionBankID: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}${questionBankPrefix}/${questionBankID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-question-banks"]);
      },
    }
  );

export const AddQAPair = () =>
  useMutation<any, unknown, any, string>(
    async ({
      questionBankID,
      question,
      answer,
      signal,
    }: {
      questionBankID: string;
      question: string;
      answer: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}${questionBankPrefix}/${questionBankID}/questions/add`,
          { to_add: [{ question: question, answer: answer }] },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-question-bank-questions"]);
      },
    }
  );

export const EditQAPair = (questionBankID: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      questionID,
      newQuestion,
      newResponse,
      signal,
    }: {
      questionID: string;
      newQuestion: string;
      newResponse: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}${questionBankPrefix}/${questionBankID}/questions/${questionID}`,
          { new_question: newQuestion, new_response: newResponse },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-question-bank-questions"]);
      },
    }
  );

export const RemoveQAPair = (questionBankID: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      questionID,
      signal,
    }: {
      questionID: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}${questionBankPrefix}/${questionBankID}/questions/${questionID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-question-bank-questions"]);
      },
    }
  );
