import React, { useState } from "react";
import RegularInput from "src/components/Input/RegularInput";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { AddAssessment } from "src/services/questionnaire-rfp/assessments";
import GeneralSnapshotDatepicker from "src/components/Datepicker/GeneralSnapshotDatepicker";
import Tags from "./Tags";
import { KeyStringVal } from "src/types/general";
import { checkIsReadOnly } from "src/utils/general";
import ToastLayout from "../../../../layouts/ToastLayout";
import CheckboxInput from "src/components/Input/CheckboxInput";
import UserInput from "src/components/Input/UserInput";

const NewAssessment = ({
  selectedSourceType,
}: {
  selectedSourceType: string;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: "",
    requesting_party: "",
    owner: "",
    reviewer: "",
    due_date: Date.now() * 1000,
    tags: [],
    source_type: selectedSourceType,
    enable_sources: true,
    enable_questionbank: true,
  });

  const addAssessment = AddAssessment();

  const handleOnClose = () => {
    setShow(false);
    addAssessment.reset();
  };
  const handleOnCloseConfirmation = () => addAssessment.reset();

  return (
    <>
      <button
        disabled={isReadOnly}
        className="blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            tags: [],
            source_type: selectedSourceType,
            enable_uploaded_doc: true,
            enable_sources: true,
            enable_questionbank: true,
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Start Assessment</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Start Assessment
          </h3>
          <section className="grid md:grid-cols-2 gap-4">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              required
            />
            <RegularInput
              label="Requesting Party"
              keyName="requesting_party"
              inputs={inputs}
              setInputs={setInputs}
            />
            <UserInput
              label="Owner"
              keyName="owner"
              inputs={inputs}
              setInputs={setInputs}
              required
            />
            <UserInput
              label="Reviewer"
              keyName="reviewer"
              inputs={inputs}
              setInputs={setInputs}
            />
          </section>
          <GeneralSnapshotDatepicker
            label="Due Date"
            keyName="due_date"
            inputs={inputs}
            setInputs={setInputs}
            onlyFutureDate
          />

          <article className="flex flex-col flex-grow gap-1 text-b2-reg">
            <h4>Must select at least one </h4>
            <CheckboxInput
              label="Search Documents"
              keyName="enable_uploaded_doc"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CheckboxInput
              label="Search Policies"
              keyName="enable_sources"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CheckboxInput
              label="Search Question Banks"
              keyName="enable_questionbank"
              inputs={inputs}
              setInputs={setInputs}
            />
          </article>
          <Tags inputs={inputs} setInputs={setInputs} />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={
                !inputs.name ||
                !inputs.owner ||
                (!inputs.enable_questionbank &&
                  !inputs.enable_sources &&
                  !inputs.enable_uploaded_doc)
              }
              className="blue-button"
              onClick={() => {
                addAssessment.mutate({
                  inputs: {
                    ...inputs,
                    tags: inputs.tags.reduce(
                      (pV: string[], cV: KeyStringVal) => [...pV, cV.tag_id],
                      []
                    ),
                  },
                });
                handleOnClose();
              }}
            >
              Done
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addAssessment.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New assessment has been created"
      />
    </>
  );
};

export default NewAssessment;
