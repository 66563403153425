import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import React, { useState } from "react";
import TablePagination from "src/components/General/TablePagination";
import Loader from "src/components/Loader/Loader";
import { pageSize } from "src/constants/general";
import { GetGRCDocumentMetadata } from "src/services/grc";
import ControlDetail from "./ControlDetail/ControlDetail";
import { GetInternalAuditSections } from "src/services/audit-management";

const Sections = ({
  documentType,
  documentID,
  auditID,
  filters,
}: {
  documentType: string;
  documentID: string;
  auditID: string;
  filters: any;
}) => {
  const [pageNumber, setPageNumber] = useState<number>(1);

  const { data: metadata } = GetGRCDocumentMetadata(documentType, documentID);
  const { data: table, status: tableStatus } = GetInternalAuditSections(
    auditID,
    pageNumber,
    filters
  );

  const documentName = metadata?.framework_name;

  const sortedTable = table?.data;
  const totalCount = table?.pager?.total_results || 0;
  const totalPages = table?.pager?.num_pages || 0;
  const beginning = pageNumber === 1 ? 1 : pageSize * (pageNumber - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <section className="flex flex-col flex-grow mb-4 min-h-screen">
      {tableStatus === "loading" ? (
        <Loader />
      ) : (
        tableStatus === "success" && (
          <section className="flex flex-col flex-grow content-start gap-3 min-h-full">
            {sortedTable ? (
              sortedTable.length > 0 ? (
                <section className="grid gap-4">
                  {sortedTable.map((section: any, sectionIndex: number) => {
                    let result = { pass: [], fail: [] } as any;
                    section.system_analysis?.findings?.forEach(
                      (finding: any) => {
                        finding.did_pass === true
                          ? result.pass.push(finding.summary)
                          : result.fail.push(finding.summary);
                      }
                    );
                    return (
                      <Disclosure key={sectionIndex}>
                        {({ open }) => (
                          <section className="grid gap-4 p-6 dark:bg-gray-800 border dark:border-gray-700 rounded-xl">
                            <Disclosure.Button className="flex items-center justify-between gap-10">
                              <h4 className="flex items-start gap-2 text-b1-reg text-left">
                                {section.section_id}{" "}
                                <span className="dark:text-gray-500">
                                  {!["-", "", null].includes(
                                    section.section_title
                                  ) && section.section_title}
                                </span>
                              </h4>
                              <article className="flex items-center gap-2 dark:text-blue-500">
                                <FontAwesomeIcon
                                  icon={open ? faChevronUp : faChevronDown}
                                />
                                <p>{open ? "Collapse" : "Expand"}</p>
                              </article>
                            </Disclosure.Button>
                            <Disclosure.Panel>
                              <section key={sectionIndex}>
                                {section.sub_sections && (
                                  <article className="grid gap-4">
                                    {section.sub_sections.map(
                                      (
                                        control: any,
                                        subSectionIndex: number
                                      ) => {
                                        return (
                                          <ControlDetail
                                            key={subSectionIndex}
                                            documentType="internal-audit"
                                            documentID={documentID}
                                            documentName={documentName}
                                            auditID={auditID}
                                            control={control}
                                          />
                                        );
                                      }
                                    )}
                                  </article>
                                )}
                              </section>
                            </Disclosure.Panel>
                          </section>
                        )}
                      </Disclosure>
                    );
                  })}
                </section>
              ) : (
                <p>No controls found</p>
              )
            ) : null}
            <TablePagination
              totalPages={totalPages}
              beginning={beginning}
              end={end}
              totalCount={totalCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </section>
        )
      )}
    </section>
  );
};

export default Sections;
