/* eslint-disable no-restricted-globals */
import DropdownLayout from "../../../layouts/DropdownLayout";
import { KeyStringVal } from "src/types/general";
import { GetGlobalQuestionnaires } from "src/services/questionnaire-rfp/assessments";
import { checkIsReadOnly } from "src/utils/general";

const SelectQuestionnaireFilter = ({
  selectedQuestionnaire,
  setSelectedQuestionnaire,
}: {
  selectedQuestionnaire: KeyStringVal;
  setSelectedQuestionnaire: (selectedQuestionnaire: KeyStringVal) => void;
}) => {
  const isReadOnly = checkIsReadOnly();

  const { data: availableQuestionnaires } = GetGlobalQuestionnaires();

  return (
    <DropdownLayout
      value={selectedQuestionnaire.document_name}
      placeholder="Select Questionnaire"
      isReadOnly={isReadOnly}
    >
      {availableQuestionnaires?.map(
        (questionnaire: KeyStringVal, index: number) => {
          const selected =
            selectedQuestionnaire.document_name === questionnaire.document_name;

          return (
            <button
              key={index}
              className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
                selected ? "dark:bg-gray-800" : ""
              } rounded-lg`}
              onClick={() => setSelectedQuestionnaire(questionnaire)}
            >
              <p>{questionnaire.document_name}</p>
            </button>
          );
        }
      )}
    </DropdownLayout>
  );
};

export default SelectQuestionnaireFilter;
