import React, { useState } from "react";
import AttachmentList from "./AttachmentList";
import RowValue from "src/components/General/RowValue";
import UpdateItem from "./UpdateItem";
import {
  AcceptItemControls,
  GetDRLItemSuggestedControls,
} from "src/services/audit-management";
import {
  checkIsReadOnly,
  handleSort,
  parseURL,
  sortRows,
} from "src/utils/general";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faSort } from "@fortawesome/free-solid-svg-icons";
import ModalLayout from "src/layouts/ModalLayout";
import TableLayout from "src/layouts/TableLayout";
import ToastLayout from "src/layouts/ToastLayout";
import { KeyStringVal } from "src/types/general";

const Item = ({
  drlID,
  filteredHeaders,
  row,
}: {
  drlID: string;
  filteredHeaders: string[];
  row: any;
}) => {
  const parsed = parseURL();
  const isReadOnly = checkIsReadOnly();

  const auditID = parsed.audit_id || "";
  const itemID = row.drl_item_id;

  const [show, setShow] = useState<boolean>(false);
  const [selectedControls, setSelectedControls] = useState<string[]>([]);
  const [sort, setSort] = useState<KeyStringVal>({
    direction: "desc",
    order_by: "created_at",
  });

  const { data: suggestedControls } = GetDRLItemSuggestedControls(
    auditID,
    drlID,
    itemID
  );
  const acceptControls = AcceptItemControls(auditID, drlID, itemID);

  const headers =
    suggestedControls && JSON.parse(suggestedControls.header.metadata);
  const filteredControlsHeaders = headers?.required.filter((col: string) => {
    if (headers.properties[col]?.hidden === "True") return null;
    return col;
  });
  const sortedRows =
    suggestedControls && sortRows(suggestedControls?.data, sort);

  const selectedAll =
    selectedControls.length === suggestedControls?.data.length;

  const handleSelectAll = () => {
    if (selectedAll) setSelectedControls([]);
    else {
      const controlIDs = suggestedControls?.data.reduce(
        (pV: string[], cV: KeyStringVal) => [...pV, cV.generated_control_id],
        []
      );
      setSelectedControls(controlIDs);
    }
  };

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => acceptControls.reset();

  return (
    <>
      <tr
        data-test="table-row"
        className="relative p-5 gap-3 break-words dark:bg-gray-800"
      >
        {filteredHeaders?.map((col: string, colIndex: number) => {
          return (
            <td
              key={`${col}-${colIndex}`}
              className="relative px-6 py-3 last:pr-16 text-left break-words"
            >
              {col === "attachments" ? (
                <AttachmentList attachments={row.attachments} />
              ) : (
                <RowValue row={row} col={col} />
              )}
              {colIndex === filteredHeaders.length - 1 && (
                <article className="absolute top-1/4 right-5 flex flex-col gap-4">
                  <UpdateItem drlID={drlID} itemID={itemID} item={row} />
                  {row.suggested_controls_count > 0 && (
                    <button
                      className="blue-text-button"
                      onClick={() => {
                        setShow(true);
                        suggestedControls.mutate({});
                      }}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </button>
                  )}
                </article>
              )}
            </td>
          );
        })}
      </tr>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 w-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Suggested Controls
          </h3>
          <TableLayout fullHeight>
            <thead className="sticky -top-1.5 dark:bg-gray-900">
              <tr>
                {filteredControlsHeaders?.map(
                  (col: string, colIndex: number) => {
                    const column = headers.properties[col];
                    if (!column) return null;

                    return (
                      <th
                        scope="col"
                        key={col}
                        className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                      >
                        <article className="capitalize flex gap-10 justify-between">
                          {colIndex === 0 && (
                            <input
                              type="checkbox"
                              disabled={isReadOnly}
                              checked={selectedAll}
                              className="white-checkbox"
                              onChange={(e) => {
                                e.stopPropagation();
                                handleSelectAll();
                              }}
                            />
                          )}
                          <h4 className="break-words">{column.title}</h4>
                          <button aria-label="sort">
                            <FontAwesomeIcon
                              icon={faSort}
                              className="mt-0.5 dark:text-gray-700"
                              onClick={() => handleSort(col, setSort)}
                            />
                          </button>
                        </article>
                      </th>
                    );
                  }
                )}
              </tr>
            </thead>
            <tbody className="divide-y dark:divide-gray-700">
              {sortedRows?.map((row: any, index: number) => {
                return (
                  <tr
                    key={index}
                    data-test="table-row"
                    className="relative p-5 gap-3 cursor-pointer break-words dark:bg-gray-800 dark:hover:bg-gray-800/70 duration-100"
                  >
                    {filteredControlsHeaders?.map(
                      (col: string, colIndex: number) => {
                        const id = row.generated_control_id;
                        const selected = selectedControls.includes(id);

                        return (
                          <td
                            key={`${index}-${colIndex}`}
                            className="relative px-6 py-3 last:pr-16 text-left break-words"
                          >
                            <article className="flex items-start gap-2">
                              {colIndex === 0 && (
                                <article className="flex items-center gap-2 pl-4 pr-3 mt-1">
                                  <input
                                    type="checkbox"
                                    disabled={isReadOnly}
                                    checked={selected}
                                    onChange={() => {
                                      if (selected)
                                        setSelectedControls(
                                          selectedControls.filter(
                                            (controlID: string) =>
                                              id !== controlID
                                          )
                                        );
                                      else
                                        setSelectedControls([
                                          ...selectedControls,
                                          id,
                                        ]);
                                    }}
                                    className="white-checkbox"
                                  />
                                </article>
                              )}
                              <RowValue row={row} col={col} />
                            </article>
                          </td>
                        );
                      }
                    )}
                  </tr>
                );
              })}
            </tbody>
          </TableLayout>
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={selectedControls.length === 0}
              className="blue-button"
              onClick={() => {
                acceptControls.mutate({ controls: selectedControls });
                handleOnClose();
              }}
            >
              <h4>Accept</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={acceptControls.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="Your selected controls have been accepted"
      />
    </>
  );
};

export default Item;
