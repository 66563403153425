/* eslint-disable react-hooks/exhaustive-deps */
import { faEdit, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import DefaultEnvFilter from "../../../components/Filter/Settings/DefaultEnvFilter";
import GroupFilter from "../../../components/Filter/Settings/GroupFilter";
import RoleFilter from "../../../components/Filter/Settings/RoleFilter";
import ModalLayout from "../../../layouts/ModalLayout";
import {
  UpdateUser,
  SendTempPasswordEmail,
} from "../../../services/settings/users";
import {
  UserDetails,
  UserGroup,
  UserRole,
  Role,
  Group,
  User,
  CustomerEnv,
} from "../../../types/settings";
import { checkIsSuperOrSiteAdmin } from "../../../utils/general";
import { GetCustomerEnvs } from "src/services/settings/environments";
import { GetAllGroups } from "src/services/settings/groups";
import { GetAllRoles } from "src/services/settings/roles";
import RegularInput from "src/components/Input/RegularInput";
import { baseURL } from "src/constants/general";

const EditUser = ({ user }: { user: User }) => {
  const userID = user.user_id;

  const isSuperOrSiteAdmin = checkIsSuperOrSiteAdmin();

  const customerID = user.customer_id;

  const [editUserDetails, setEditUserDetails] = useState<UserDetails>({
    user_name: "",
    user_email: "",
    default_env: "",
    group_ids: [],
    role_ids: [],
  });
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const { data: customerEnvs } = GetCustomerEnvs(customerID);
  const { data: allGroups } = GetAllGroups(customerID, isSuperOrSiteAdmin);
  const { data: allRoles } = GetAllRoles(customerID);
  const updateUser = UpdateUser(customerID);
  const sendTempPasswordEmail = SendTempPasswordEmail(customerID);

  useEffect(() => {
    setEditUserDetails({
      user_name: user.user_name || "",
      user_email: user.user_email || "",
      default_env: user.default_env || "",
      group_ids:
        user.groups?.reduce(
          (pV: string[], cV: UserGroup) => [...pV, cV.group_id],
          []
        ) || [],
      role_ids:
        user.roles?.reduce(
          (pV: string[], cV: UserRole) => [...pV, cV.role_id],
          []
        ) || [],
    });
  }, [user]);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        className="absolute right-5 top-1/4 white-text-button"
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="flex flex-col flex-grow gap-4">
          <h3 className="flex items-center gap-2 text-t1-semi">Edit User</h3>
          {user?.is_oidc === false && (
            <button
              className="mx-auto border-b dark:border-blue-600 dark:hover:border-blue-600/60 duration-100"
              onClick={() => {
                sendTempPasswordEmail.mutate(
                  {
                    tempPasswordEmail: {
                      email: user.user_email,
                      user_id: user.user_id,
                      base_url: String(baseURL).includes("localhost")
                        ? "http://localhost:3000"
                        : baseURL,
                      expiration_time_in_mins: 2880,
                    },
                  },
                  {
                    onSuccess: () => setEmailSent(true),
                  }
                );
              }}
            >
              Reset Password
            </button>
          )}
          <ModalLayout
            showModal={emailSent}
            onClose={() => setEmailSent(false)}
          >
            <article className="grid content-start gap-2 p-5 px-10 mx-auto text-center">
              <img src="/general/true.svg" alt="true" className="mx-auto" />
              <p>
                An email with the temporary password has been sent out again.
                The password reset link will expire in 48 hours, so please make
                sure to click through and reset the password in time.
              </p>
            </article>
            <button
              className="place-self-end blue-button"
              onClick={() => setEmailSent(false)}
            >
              Close
            </button>
          </ModalLayout>
          <RegularInput
            label="Name"
            keyName="user_name"
            inputs={editUserDetails}
            setInputs={setEditUserDetails}
            required
          />
          <RegularInput
            label="Email"
            keyName="user_email"
            inputs={editUserDetails}
            setInputs={setEditUserDetails}
            disabled={true}
          />
          <article className="grid gap-10 mt-3">
            <article className="grid items-center gap-4">
              {/* update default env */}
              <DefaultEnvFilter
                envTypes={customerEnvs}
                details={editUserDetails}
                label="Default environment"
                value={
                  customerEnvs?.find(
                    (customerEnv: CustomerEnv) =>
                      customerEnv.env_id === editUserDetails.default_env
                  )?.env_type || ""
                }
                setValue={setEditUserDetails}
                list={customerEnvs?.reduce(
                  (pV: string[], cV: CustomerEnv) => [...pV, cV.env_type],
                  []
                )}
                showAbove
              />

              {/* update groups */}
              <article className="grid gap-2 w-full">
                <GroupFilter
                  allGroups={allGroups}
                  details={editUserDetails}
                  label="Group"
                  value={
                    allGroups?.find(
                      (group: Group) =>
                        group.group_id ===
                        editUserDetails.group_ids[
                          editUserDetails.group_ids.length - 1
                        ]
                    )?.group_name || ""
                  }
                  setValue={setEditUserDetails}
                  list={allGroups?.reduce(
                    (pV: string[], cV: Group) => [...pV, cV.group_name],
                    []
                  )}
                />
                <ul className="flex flex-wrap items-center gap-2 text-b2-reg">
                  {editUserDetails.group_ids.map((groupID: string) => {
                    const groupName = allGroups?.find(
                      (group: Group) => group.group_id === groupID
                    )?.group_name;
                    if (!groupName) return null;
                    return (
                      <li
                        key={groupID}
                        className="flex items-center gap-2 px-2 py-1 dark:bg-gray-700 rounded"
                      >
                        <span>{groupName}</span>
                        <button
                          className="hover-icon"
                          onClick={() =>
                            setEditUserDetails({
                              ...editUserDetails,
                              group_ids: editUserDetails.group_ids.filter(
                                (curGroupID: string) => curGroupID !== groupID
                              ),
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </article>

              {/* update roles */}
              <article className="grid gap-2 w-full">
                <RoleFilter
                  allRoles={allRoles}
                  details={editUserDetails}
                  value={
                    allRoles?.find(
                      (role: Role) =>
                        role.role_id ===
                        editUserDetails.role_ids[
                          editUserDetails.role_ids.length - 1
                        ]
                    )?.role_name || ""
                  }
                  setValue={setEditUserDetails}
                  list={
                    [
                      ...new Set(
                        allRoles?.reduce(
                          (pV: string[], cV: Role) => [...pV, cV.role_name],
                          []
                        )
                      ),
                    ] as string[]
                  }
                  showAbove
                />
                <ul className="flex flex-wrap items-center gap-2 text-b2-reg">
                  {editUserDetails.role_ids.map((roleID: string) => {
                    const roleName = allRoles?.find(
                      (role: Role) => role.role_id === roleID
                    )?.role_name;
                    if (!roleName) return null;
                    return (
                      <li
                        key={roleID}
                        className="flex items-center gap-2 px-2 py-1 dark:bg-gray-700 rounded"
                      >
                        <span>{roleName}</span>
                        <button
                          className="hover-icon"
                          onClick={() =>
                            setEditUserDetails({
                              ...editUserDetails,
                              role_ids: editUserDetails.role_ids.filter(
                                (curRoleID: string) => curRoleID !== roleID
                              ),
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </article>
            </article>
            <button
              disabled={
                editUserDetails.user_name === "" ||
                editUserDetails.user_email === ""
              }
              className="place-self-end blue-button"
              onClick={() => {
                handleOnClose();
                updateUser.mutate({
                  userID,
                  user: editUserDetails,
                });
              }}
            >
              Done
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default EditUser;
