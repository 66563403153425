import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { RemoveAudit } from "src/services/audit-management";
import { checkIsReadOnly } from "src/utils/general";

const DeleteAudit = ({ auditID }: { auditID: string }) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);

  const removeAudit = RemoveAudit();

  const handleOnClose = () => setShow(false);

  const deleting = removeAudit.status === "loading";

  return (
    <>
      <button
        disabled={isReadOnly || deleting}
        className="hard-delete-button"
        onClick={() => setShow(true)}
      >
        Delete Audit
      </button>
      <ModalLayout showModal={show} onClose={() => setShow(false)}>
        <section className="grid gap-4">
          <h2 className="text-subt1-semi">Delete Internal Audit</h2>
          <h4 className="text-b1-reg">
            Are you sure you want to delete this internal audit?
          </h4>
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={deleting}
              className="red-button"
              onClick={() => {
                removeAudit.mutate({
                  auditID: auditID,
                });
                handleOnClose();
              }}
            >
              Delete
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default DeleteAudit;
