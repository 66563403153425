import React from "react";
import DocumentRequestList from "./DocumentRequestList";
import OverallStats from "./OverallStats";

const Overview = ({ auditID }: { auditID: string }) => {
  return (
    <section className="flex flex-col flex-grow gap-4">
      <OverallStats auditID={auditID} />
      <DocumentRequestList auditID={auditID} />
    </section>
  );
};

export default Overview;
