import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { checkIsReadOnly } from "src/utils/general";
import { EditDocumentRequest } from "src/services/erc/attachments/document-requests";
import RegularInput from "src/components/Input/RegularInput";

const UpdateRequest = ({ drlID, request }: { drlID: string; request: any }) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});

  const edit = EditDocumentRequest(drlID);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        disabled={isReadOnly}
        className="place-self-end edit-button"
        onClick={() => {
          setShow(true);
          setInputs(request);
        }}
      >
        <FontAwesomeIcon icon={faEdit} />
        <h4>Edit</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 w-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Edit Request</h3>
          <RegularInput
            label="Notes"
            keyName="notes"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              className="blue-button"
              onClick={() => {
                edit.mutate({
                  inputs,
                });

                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default UpdateRequest;
