import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import {
  ArchiveEntityType,
  RestoreEntityType,
} from "src/services/erc/entities/entity-types";
import { checkIsReadOnly } from "src/utils/general";

const ArchiveRestoreEntityType = ({
  entityTypeID,
  entityType,
}: {
  entityTypeID: string;
  entityType: any;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);

  const archive = ArchiveEntityType(entityTypeID);
  const restore = RestoreEntityType(entityTypeID);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <nav className="flex items-center gap-1 p-1 w-max dark:bg-gray-900 rounded-lg">
        <button
          disabled={isReadOnly}
          className="status-button"
          onClick={(e) => {
            e.stopPropagation();
            if (entityType.status?.toLowerCase() !== "archived") setShow(true);
            else restore.mutate({});
          }}
        >
          {entityType.status?.toLowerCase() === "archived"
            ? "recover"
            : "archive"}
        </button>
      </nav>
      <ModalLayout showModal={show} onClose={() => setShow(false)}>
        <section className="grid gap-4">
          <h2 className="text-subt1-semi">Archive Entity Type</h2>
          <h4 className="text-b1-reg">
            Are you sure you want to archive this entity type?
          </h4>
          <section className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              className="red-button"
              onClick={() => {
                archive.mutate({});
                handleOnClose();
              }}
            >
              Archive
            </button>
          </section>
        </section>
      </ModalLayout>
    </>
  );
};

export default ArchiveRestoreEntityType;
