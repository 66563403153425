/* eslint-disable no-restricted-globals */
import { Handle, Position } from "reactflow";
import { motion } from "framer-motion";
import { memo } from "react";

const CONTROL = memo(({ data }: { data: any }) => {
  return (
    <motion.section initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Handle type="target" position={Position.Left} style={{ opacity: 0 }} />
      <section className="relative group grid gap-2 p-4 w-[20rem] min-h-full text-white dark:bg-blue-800 black-shadow rounded-xl">
        <span className="text-b3-reg">CONTROL</span>
        <h4 className="text-b1-semi">
          {data.name?.slice(0, 30)}
          {data.name?.length > 30 && "..."}
        </h4>
        <p className="text-b2-reg">
          {data.node_info?.slice(0, 100)}
          {data.node_info?.length > 100 && "..."}
        </p>
      </section>
    </motion.section>
  );
});

export default CONTROL;
