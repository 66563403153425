/* eslint-disable react-hooks/exhaustive-deps */
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import TablePagination from "src/components/General/TablePagination";
import { pageSize } from "src/constants/general";
import { checkIsReadOnly, convertToUTCString } from "src/utils/general";
import { useGRCStore } from "src/stores/grc";
import Loader from "src/components/Loader/Loader";
import DeleteQuestionBank from "./DeleteQuestionBank";
import { KeyStringVal } from "src/types/general";
import { UseQuestionBanks } from "src/services/questionnaire-rfp/assessments";
import { GetQuestionBanks } from "src/services/questionnaire-rfp/question-banks";

const QuestionBankList = () => {
  const isReadOnly = checkIsReadOnly();

  const { setSelectedGRCQuestionBank } = useGRCStore();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedQuestionBankIDs, setSelectedQuestionBankIDs] = useState<
    string[]
  >([]);

  const { data: questionBanks, status: questionBankStatus } =
    GetQuestionBanks(pageNumber);
  const useQuestionBank = UseQuestionBanks();

  const totalCount = questionBanks?.pager.total_results || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  const allQuestionBankIDs = questionBanks?.data.reduce(
    (pV: string[], cV: KeyStringVal) => [...pV, cV.document_id],
    []
  );
  const selectedAll =
    selectedQuestionBankIDs.length > 0 &&
    questionBanks?.data.every((questionBank: KeyStringVal) =>
      selectedQuestionBankIDs.includes(questionBank.document_id)
    );

  const handleSelectAll = () => {
    if (selectedAll)
      setSelectedQuestionBankIDs(
        selectedQuestionBankIDs.filter(
          (questionBankID: string) =>
            !allQuestionBankIDs.includes(questionBankID)
        )
      );
    else {
      const questionBankIDs = questionBanks?.data
        .map((questionBank: KeyStringVal) => {
          if (selectedQuestionBankIDs.includes(questionBank.document_id))
            return null;
          else return questionBank.document_id;
        })
        .filter(Boolean);
      setSelectedQuestionBankIDs([
        ...selectedQuestionBankIDs,
        ...questionBankIDs,
      ]);
    }
  };

  useEffect(() => {
    if (questionBanks?.data.length > 0 && selectedQuestionBankIDs.length === 0)
      handleSelectAll();
  }, [questionBanks]);

  return (
    <section className="flex flex-col flex-grow gap-4">
      {questionBankStatus === "loading" ? (
        <Loader />
      ) : questionBanks?.data.length > 0 ? (
        <section className="flex flex-col flex-grow gap-4">
          <article className="flex items-center place-content-end gap-2">
            <button
              disabled={isReadOnly}
              className="black-button"
              onClick={handleSelectAll}
            >
              {selectedAll ? "Deselect All" : "Select All"}
            </button>
            {selectedQuestionBankIDs.length > 0 &&
              useQuestionBank.status !== "success" && (
                <button
                  disabled={isReadOnly}
                  className="blue-button"
                  onClick={() =>
                    useQuestionBank.mutate({
                      questionBankIDs: selectedQuestionBankIDs,
                    })
                  }
                >
                  Apply for Use
                </button>
              )}
          </article>
          <ul className="flex flex-col flex-grow gap-4">
            {questionBanks?.data.map((questionBank: any, index: number) => {
              const selected = selectedQuestionBankIDs.includes(
                questionBank.document_id
              );
              return (
                <li
                  key={index}
                  className="flex items-start gap-2 px-6 py-4 dark:bg-gray-800 rounded-xl"
                >
                  <input
                    type="checkbox"
                    disabled={isReadOnly}
                    checked={selected}
                    className="white-checkbox"
                    onChange={() => {
                      useQuestionBank.reset();
                      if (selected) {
                        setSelectedQuestionBankIDs(
                          selectedQuestionBankIDs.filter(
                            (id: string) => id !== questionBank.document_id
                          )
                        );
                      } else
                        setSelectedQuestionBankIDs([
                          ...selectedQuestionBankIDs,
                          questionBank.document_id,
                        ]);
                    }}
                  />
                  <article className="flex flex-col flex-grow">
                    <h4 className="text-subt1-semi">{questionBank.name}</h4>
                    <article className="flex items-center gap-1 text-b2-reg dark:text-gray-400">
                      <h4>Created at</h4>
                      <p>{convertToUTCString(questionBank.record_time)}</p>
                    </article>
                  </article>
                  <article className="flex items-center">
                    <DeleteQuestionBank selectedQuestionBank={questionBank} />
                    <button
                      className="flex items-center gap-2 px-4 py-1 text-b2-reg dark:text-blue-600 dark:hover:text-blue-600/70 duration-100"
                      onClick={() => {
                        setSelectedGRCQuestionBank(questionBank);
                        sessionStorage.question_bank_id =
                          questionBank.document_id;
                        sessionStorage.question_bank_name = questionBank.name;
                      }}
                    >
                      <FontAwesomeIcon icon={faEye} /> View questions
                    </button>
                  </article>
                </li>
              );
            })}
          </ul>
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </section>
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/frameworks-placeholder.svg"
            alt="frameworks placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-t1-bold">Question Banks</h4>
            <h4>No question banks available</h4>
          </article>
        </section>
      )}
    </section>
  );
};

export default QuestionBankList;
