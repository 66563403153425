/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { useState } from "react";
import { sortRows } from "../../../utils/general";
import { GetAllGroups } from "../../../services/settings/groups";
import Users from "./Users";
import { Group } from "../../../types/settings";
import AddGroup from "./AddGroup";
import EditGroup from "./EditGroup";
import { getCustomerID } from "../../../utils/general";
import { ListHeader } from "../../../types/general";
import TableLayout from "../../../layouts/TableLayout";
import SortColumn from "src/components/Button/SortColumn";
import { initialSort, pageSize } from "src/constants/general";
import RowValue from "src/components/General/RowValue";
import TablePagination from "src/components/General/TablePagination";

export const groupHeaders = [
  { display_name: "Group Name", property_name: "group_name" },
  { display_name: "Description", property_name: "group_description" },
  { display_name: "Updated", property_name: "updated_at" },
  { display_name: "Created", property_name: "created_at" },
  { display_name: "Users", property_name: "users_count" },
];

const Groups = () => {
  const customerID = getCustomerID();

  const [sort, setSort] = useState(initialSort);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const { data: allGroups } = GetAllGroups(customerID, false);

  const sortedGroups = allGroups && sortRows(allGroups, sort);
  const totalCount = allGroups?.length || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning = pageNumber === 1 ? 1 : pageSize * (pageNumber - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <section className="grid content-start gap-6 pb-24 w-full h-full">
      <header className="flex items-center justify-between gap-4">
        <h4 className="text-subt1-semi">Groups ({allGroups?.length || 0})</h4>
        <AddGroup />
      </header>
      <TableLayout fullHeight>
        <thead className="sticky -top-1.5 dark:bg-gray-900">
          <tr>
            {groupHeaders.map((col: ListHeader) => {
              return (
                <th
                  scope="col"
                  key={col.display_name}
                  className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                >
                  <article className="capitalize flex gap-10 justify-between">
                    <h4>{col.display_name}</h4>
                    <SortColumn
                      propertyName={col.property_name}
                      setSort={setSort}
                    />
                  </article>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="divide-y dark:divide-gray-700">
          {sortedGroups?.map((group: Group) => {
            return (
              <tr
                key={group.group_id}
                className="relative p-5 gap-3 break-words dark:bg-gray-800"
              >
                {groupHeaders.map((col: ListHeader, colIndex: number) => {
                  return (
                    <td
                      key={`${group.group_id}-${colIndex}`}
                      className="relative px-6 py-3 last:pr-16 text-left break-words"
                    >
                      {col.property_name === "users_count" ? (
                        <Users selectedGroup={group.group_id} />
                      ) : (
                        <RowValue row={group} col={col.property_name} />
                      )}

                      {colIndex === groupHeaders.length - 1 && (
                        <article className="absolute right-5 top-1/4 flex items-center">
                          <EditGroup group={group} />
                        </article>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </TableLayout>
      <TablePagination
        totalPages={totalPages}
        beginning={beginning}
        end={end}
        totalCount={totalCount}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
    </section>
  );
};

export default Groups;
