import React from "react";

const AuditTabs = ({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: string;
  setSelectedTab: (selectedTab: string) => void;
}) => {
  return (
    <nav className="flex flex-wrap items-center gap-1 p-1 w-max dark:bg-gray-900 rounded-lg">
      {["overview", "controls"].map((tab) => {
        return (
          <button
            key={tab}
            className={`px-4 py-1 text-center capitalize ${
              selectedTab === tab
                ? "dark:text-white dark:bg-gray-800"
                : "dark:text-gray-500 dark:hover:bg-gray-500/30 duration-100"
            } rounded-lg`}
            onClick={() => setSelectedTab(tab)}
          >
            {tab}
          </button>
        );
      })}
    </nav>
  );
};

export default AuditTabs;
