import React, { Fragment, useState } from "react";
import Loader from "src/components/Loader/Loader";
import { faChevronRight, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TablePagination from "src/components/General/TablePagination";
import { pageSize } from "src/constants/general";
import TableLayout from "src/layouts/TableLayout";
import { KeyStringVal } from "src/types/general";
import { sortRows, handleSort } from "src/utils/general";
import RowValue from "src/components/General/RowValue";
import { useNavigate } from "react-router-dom";
import { GetAuditDRL } from "src/services/audit-management";

const DocumentRequestList = ({ auditID }: { auditID: string }) => {
  const navigate = useNavigate();

  const [sort, setSort] = useState<KeyStringVal>({
    direction: "desc",
    order_by: "created_at",
  });
  const [pageNumber, setPageNumber] = useState<number>(1);

  const { data: documentRequests, status } = GetAuditDRL(auditID, pageNumber);

  const headers =
    documentRequests && JSON.parse(documentRequests.header.metadata);
  const filteredHeaders = headers?.required.filter((col: string) => {
    if (headers.properties[col]?.hidden === "True") return null;
    return col;
  });
  const sortedRows = documentRequests && sortRows(documentRequests?.data, sort);
  const totalCount = documentRequests?.pager.total_results || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <section className="flex flex-col flex-grow gap-4 pb-24">
      <h4 className="text-subt1-semi">Document Requests</h4>
      {status === "loading" ? (
        <Loader />
      ) : documentRequests?.data.length > 0 ? (
        <section className="flex flex-col flex-grow gap-4">
          <TableLayout fullHeight>
            <thead className="sticky -top-1.5 dark:bg-gray-900">
              <tr>
                {filteredHeaders?.map((col: string) => {
                  const column = headers.properties[col];
                  if (!column) return null;

                  return (
                    <th
                      scope="col"
                      key={col}
                      className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                    >
                      <article className="capitalize flex gap-10 justify-between">
                        <h4 className="break-words">{column.title}</h4>
                        <button aria-label="sort">
                          <FontAwesomeIcon
                            icon={faSort}
                            className="mt-0.5 dark:text-gray-700"
                            onClick={() => handleSort(col, setSort)}
                          />
                        </button>
                      </article>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="divide-y dark:divide-gray-700">
              {sortedRows?.map((row: any, index: number) => {
                return (
                  <Fragment key={index}>
                    <tr
                      key={index}
                      data-test="table-row"
                      className="relative p-5 gap-3 cursor-pointer break-words dark:bg-gray-800 dark:hover:bg-gray-800/70 duration-100"
                      onClick={() =>
                        navigate(
                          `/erc/document-requests/details?drl_id=${row.drl_id}&audit_id=${auditID}`
                        )
                      }
                    >
                      {filteredHeaders?.map((col: string, colIndex: number) => {
                        return (
                          <td
                            key={`${index}-${colIndex}`}
                            className="relative px-6 py-3 last:pr-16 text-left break-words"
                          >
                            <RowValue row={row} col={col} />
                            {colIndex === filteredHeaders.length - 1 &&
                              row.mapping_count > 0 && (
                                <a
                                  href={`/erc/document-request-mapping/details?audit_id=${auditID}&drl_id=${row.drl_id}`}
                                  className="absolute top-1/4 right-5 blue-text-button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  <FontAwesomeIcon icon={faChevronRight} />
                                  <p>{row.mapping_count} Mapped</p>
                                </a>
                              )}
                          </td>
                        );
                      })}
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </TableLayout>
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </section>
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/third-party-risk-placeholder.svg"
            alt="reviews placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-t1-bold">Document Requests</h4>
            <h4>No document requests available</h4>
          </article>
        </section>
      )}
    </section>
  );
};

export default DocumentRequestList;
