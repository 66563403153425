/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useLayoutEffect, useState } from "react";
import ReactFlow, {
  addEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useStoreApi,
} from "reactflow";
import Loader from "src/components/Loader/Loader";
import { nodeTypes, edgeTypes, showVariants } from "src/constants/general";
import GraphControls from "src/components/Graph/GraphControls";
import { onInit, highlightPath, resetNodeStyles } from "src/utils/graph";
import { GetDRLMapping } from "src/services/audit-management";
import { renderDRLGraph } from "src/utils/grc";
import { parseURL } from "src/utils/general";
import ModalLayout from "src/layouts/ModalLayout";
import ReturnPage from "src/components/Button/ReturnPage";
import { motion } from "framer-motion";
import PageLayout from "src/layouts/PageLayout";

const Suggestions = () => {
  const parsed = parseURL();

  const auditID = String(parsed.audit_id) || "";
  const drlID = String(parsed.drl_id) || "";

  const [show, setShow] = useState<boolean>(false);
  const [selectedNode, setSelectedNode] = useState<any>({});
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );
  const [minZoom, setMinZoom] = useState<number>(0);
  const store = useStoreApi();
  const reactFlowInstance = useReactFlow();

  const { data: mapping, status } = GetDRLMapping(auditID, drlID);

  const handleOnClose = () => {
    setShow(false);
    setSelectedNode({});
  };

  useLayoutEffect(() => {
    let nodes = [];
    let edges = [];
    if (mapping) {
      nodes = mapping.nodes;
      edges = mapping.edges;
    }
    if (nodes.length > 0)
      renderDRLGraph(
        nodes,
        edges,
        setNodes,
        setEdges,
        store,
        setMinZoom,
        reactFlowInstance
      );
  }, [mapping, minZoom]);

  return (
    <PageLayout>
      <motion.main
        variants={showVariants}
        initial="hidden"
        animate="visible"
        className="flex flex-col flex-grow gap-4 p-4 w-full h-screen text-base overflow-auto scrollbar"
      >
        <article className="flex items-center gap-2">
          <ReturnPage />
          <h4 className="flex items-center gap-2 text-b3-reg">
            <span className="dark:text-gray-500">
              Entities, Risks, & Controls
            </span>{" "}
            <span>/</span>
            <span>Document Request</span>
          </h4>
        </article>
        <section className="flex flex-col flex-grow h-full dark:bg-gray-900 black-shadow">
          <section className="relative flex flex-grow flex-col pb-11 h-full">
            {status === "loading" ? (
              <Loader />
            ) : nodes.length > 0 ? (
              <section className="flex flex-col flex-grow w-full h-full">
                <ReactFlow
                  onInit={(reactFlowInstance) =>
                    onInit(store, nodes, setMinZoom, reactFlowInstance)
                  }
                  minZoom={minZoom}
                  maxZoom={1}
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={onConnect}
                  nodeTypes={nodeTypes}
                  edgeTypes={edgeTypes}
                  panOnDrag={true}
                  panOnScroll={true}
                  zoomOnScroll={true}
                  zoomOnDoubleClick={false}
                  selectNodesOnDrag={false}
                  onNodeMouseEnter={(_event, node) =>
                    highlightPath(node, nodes, edges, setNodes, setEdges)
                  }
                  onNodeMouseLeave={() => resetNodeStyles(setNodes, setEdges)}
                  onNodeClick={(e, node) => {
                    setShow(true);
                    setSelectedNode(node.data);
                  }}
                ></ReactFlow>
                <ModalLayout showModal={show} onClose={handleOnClose}>
                  <section className="grid gap-4">
                    <h2 className="text-subt1-semi">{selectedNode?.name}</h2>
                    <h2 className="p-6 text-b1-reg dark:bg-gray-900 rounded-xl">
                      {selectedNode?.node_info}
                    </h2>
                  </section>
                </ModalLayout>
              </section>
            ) : (
              <p>No results found</p>
            )}
            <GraphControls nodes={nodes} setMinZoom={setMinZoom} />
          </section>
        </section>
      </motion.main>
    </PageLayout>
  );
};

export default Suggestions;
