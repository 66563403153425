import React, { useEffect, useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import FileInput from "src/components/Input/FileInput";
import { AddGlobalQuestionSet } from "src/services/third-party-risk/questions/global-questions";
import { read, utils } from "xlsx";
import ToastLayout from "src/layouts/ToastLayout";
import { checkIsReadOnly } from "src/utils/general";
import Sheet from "src/components/GRC/FileSheets/Sheet";

const NewGlobalQuestionSet = () => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    file: "",
  });
  const [headers, setHeaders] = useState<any>({});
  const [numOfRows, setNumOfRows] = useState<any>({});
  const [markedAsQuestionCol, setMarkedAsQuestionCol] = useState<any>({});
  const [markedAsAnswerCol, setMarkedAsAnswerCol] = useState<any>({});

  const addQuestionSet = AddGlobalQuestionSet();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addQuestionSet.reset();

  const ignoreResponse = !inputs.file?.name?.includes("xlsx");

  useEffect(() => {
    if (inputs.file) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(inputs.file);
      fileReader.onload = (event) => {
        let data = event.target?.result;
        let workbook = read(data, { type: "binary" });
        const tempHeaders = {} as any;
        workbook.SheetNames.forEach((sheet: string) => {
          const rowObject = utils.sheet_to_json(workbook.Sheets[sheet], {
            header: 1,
            defval: "",
          });
          let curHeaders = [];
          if (numOfRows[sheet] > 1) {
            const maxHeaderRows = Math.max(
              ...(workbook.Sheets[sheet]["!merges"] || [])?.reduce(
                (pV: number[], cV: any) => [...pV, cV.e.r],
                []
              )
            );
            const topCols = rowObject[0] as string[];
            let topColIdx = 0;
            curHeaders = (
              rowObject[Math.max(maxHeaderRows, 2)] as string[]
            )?.map((col, idx) => {
              if (col !== "") return `${topCols[topColIdx]} - ${col}`;
              else {
                topColIdx = idx + 1;
                return topCols[idx];
              }
            });
          } else curHeaders = rowObject[0] as string[];
          tempHeaders[sheet] = curHeaders;
        });
        setHeaders(tempHeaders);
      };
    } else setHeaders({});
  }, [inputs.file, numOfRows]);

  return (
    <>
      <button
        disabled={isReadOnly}
        className="blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            file: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faUpload} />
        <h4>Upload Question Set</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Upload Question Set
          </h3>
          <FileInput
            label="Question Set"
            keyName="file"
            types={["pdf", "docx", "xlsx"]}
            inputs={inputs}
            setInputs={setInputs}
          />
          {!ignoreResponse && Object.keys(headers).length > 0 && (
            <section className="grid gap-4 overflow-auto scrollbar">
              {Object.keys(headers).map((sheet, index) => {
                return (
                  <Sheet
                    key={index}
                    headers={headers}
                    sheet={sheet}
                    numOfRows={numOfRows}
                    setNumOfRows={setNumOfRows}
                    markedAsQuestionCol={markedAsQuestionCol}
                    setMarkedAsQuestionCol={setMarkedAsQuestionCol}
                    markedAsAnswerCol={markedAsAnswerCol}
                    setMarkedAsAnswerCol={setMarkedAsAnswerCol}
                    ignoreResponse={ignoreResponse}
                  />
                );
              })}
            </section>
          )}
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={
                inputs.file === "" ||
                (!ignoreResponse &&
                  (Object.keys(markedAsQuestionCol).length === 0 ||
                    Object.keys(markedAsAnswerCol).length === 0))
              }
              className="blue-button"
              onClick={() => {
                const formData = new FormData();

                formData.append("file", inputs.file);

                if (!ignoreResponse) {
                  let toExtract = {} as any;
                  Object.keys(headers).forEach((sheet, sheetIndex: number) => {
                    if (
                      markedAsQuestionCol.hasOwnProperty(sheet) &&
                      markedAsQuestionCol.hasOwnProperty(sheet) &&
                      numOfRows.hasOwnProperty(sheet)
                    )
                      toExtract[sheetIndex] = {
                        questions: markedAsQuestionCol[sheet],
                        ...(!ignoreResponse && {
                          answers: markedAsAnswerCol[sheet],
                        }),
                        num_header_rows: numOfRows[sheet],
                      };
                  });
                  formData.append("to_extract", JSON.stringify(toExtract));
                }

                addQuestionSet.mutate({
                  formData,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addQuestionSet.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="Please wait while the system is taking some time to process all questions..."
      />
    </>
  );
};

export default NewGlobalQuestionSet;
