import React, { Fragment, useState } from "react";
import Loader from "src/components/Loader/Loader";
import { faSort, faDownload, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TablePagination from "src/components/General/TablePagination";
import { pageSize } from "src/constants/general";
import TableLayout from "src/layouts/TableLayout";
import { KeyStringVal } from "src/types/general";
import { sortRows, handleSort, convertToUTCString } from "src/utils/general";
import ModalLayout from "src/layouts/ModalLayout";
import DeleteAttachment from "./DeleteAttachment";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import RowValue from "src/components/General/RowValue";
import {
  DownloadEntityAttachment,
  GetEntityAttachments,
} from "src/services/erc/entities/entities";
import NewAttachment from "./NewAttachment";

const AttachmentList = ({ entityID }: { entityID: string }) => {
  const [sort, setSort] = useState<KeyStringVal>({
    direction: "desc",
    order_by: "created_at",
  });
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedFile, setSelectedFile] = useState<string>("");

  const { data: attachments, status: attachmentStatus } = GetEntityAttachments(
    entityID,
    pageNumber
  );
  const download = DownloadEntityAttachment(entityID);

  const headers = attachments && JSON.parse(attachments.header.metadata);
  const filteredHeaders = headers?.required.filter((col: string) => {
    if (headers.properties[col]?.hidden === "True") return null;
    return col;
  });
  const sortedRows = attachments && sortRows(attachments?.data, sort);
  const totalCount = attachments?.pager.total_results || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  const handleOnClose = () => setSelectedFile("");

  return (
    <section className="flex flex-col flex-grow gap-4 pb-24">
      <header className="flex items-center justify-between gap-10">
        <h4 className="text-subt1-semi">Attachments</h4>
        <NewAttachment entityID={entityID} />
      </header>
      {attachmentStatus === "loading" ? (
        <Loader />
      ) : attachments?.data.length > 0 ? (
        <section className="flex flex-col flex-grow gap-4">
          <TableLayout fullHeight>
            <thead className="sticky -top-1.5 dark:bg-gray-900">
              <tr>
                {filteredHeaders?.map((col: string) => {
                  const column = headers.properties[col];
                  if (!column) return null;

                  return (
                    <th
                      scope="col"
                      key={col}
                      className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                    >
                      <article className="capitalize flex gap-10 justify-between">
                        <h4 className="break-words">{column.title}</h4>
                        <button aria-label="sort">
                          <FontAwesomeIcon
                            icon={faSort}
                            className="mt-0.5 dark:text-gray-700"
                            onClick={() => handleSort(col, setSort)}
                          />
                        </button>
                      </article>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="divide-y dark:divide-gray-700">
              {sortedRows?.map((row: any, index: number) => {
                const attachmentID = row.attachment_id;
                const uri = row.attachment_url;

                const fileType = uri?.slice(
                  uri?.lastIndexOf(".") + 1,
                  uri?.indexOf("?")
                );

                return (
                  <Fragment key={index}>
                    <tr
                      key={index}
                      data-test="table-row"
                      className="relative p-5 gap-3 cursor-pointer break-words dark:bg-gray-800 dark:hover:bg-gray-800/70 duration-100"
                      // onClick={() =>
                      //   navigate(
                      //     `/erc/attachments/details?attachment_id=${row.attachment_id}`
                      //   )
                      // }
                    >
                      {filteredHeaders?.map((col: string, colIndex: number) => {
                        return (
                          <td
                            key={`${index}-${colIndex}`}
                            className="relative px-6 py-3 last:pr-16 text-left break-words"
                          >
                            {col === "attachment_url" ? (
                              <button
                                className="flex items-center gap-1 text-left break-all dark:text-blue-400 dark:hover:text-blue-400/70 duration-100"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedFile(attachmentID);
                                }}
                              >
                                <FontAwesomeIcon icon={faEye} />
                                View file
                              </button>
                            ) : (
                              <RowValue row={row} col={col} />
                            )}
                            {colIndex === filteredHeaders.length - 1 && (
                              <button
                                className="absolute top-1/4 right-5 dark:hover:text-blue-500/60 duration-100"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let a = document.createElement("a");
                                  a.href = row.download_uri;
                                  a.target = "_blank";
                                  a.click();
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faDownload}
                                  className="text-blue-500"
                                />{" "}
                              </button>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                    <ModalLayout
                      showModal={selectedFile === attachmentID}
                      onClose={handleOnClose}
                    >
                      <section className="flex flex-col flex-grow gap-4">
                        <article className="flex flex-col flex-gfile gap-3 text-b1-reg">
                          <article className="flex flex-col flex-gfile">
                            <h4 className="text-subt1-semi">
                              {row.attachment_name}
                            </h4>
                            <article className="flex items-center gap-1 text-b2-reg dark:text-gray-400">
                              <h4>Uploaded at</h4>
                              <p>{convertToUTCString(row.uploaded_at)}</p>
                            </article>
                          </article>
                        </article>
                        <article className="flex items-center place-content-end gap-4">
                          <button
                            className="small-black-button"
                            onClick={() => {
                              download.mutate(
                                {
                                  attachmentID,
                                },
                                {
                                  onSuccess: (data) => {
                                    let a = document.createElement("a");
                                    a.href = data;
                                    a.target = "_blank";
                                    a.click();
                                  },
                                }
                              );
                            }}
                          >
                            <FontAwesomeIcon icon={faDownload} /> Download as
                            File
                          </button>
                          <DeleteAttachment
                            entityID={entityID}
                            attachmentID={attachmentID}
                          />
                        </article>
                        <DocViewer
                          documents={[
                            {
                              uri,
                              fileType,
                            },
                          ]}
                          pluginRenderers={DocViewerRenderers}
                          config={{
                            header: {
                              disableHeader: false,
                              disableFileName: true,
                              retainURLParams: false,
                            },
                          }}
                        />
                      </section>
                    </ModalLayout>
                  </Fragment>
                );
              })}
            </tbody>
          </TableLayout>
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </section>
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/third-party-risk-placeholder.svg"
            alt="reviews placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-t1-bold">Attachments</h4>
            <h4>No attachments available</h4>
          </article>
        </section>
      )}
    </section>
  );
};

export default AttachmentList;
