import React from "react";
import { GetAuditOverviewStats } from "src/services/audit-management";

const OverallStats = ({ auditID }: { auditID: string }) => {
  const { data } = GetAuditOverviewStats(auditID);

  return (
    <section className="grid grid-cols-3 grid-rows-2 gap-2 p-4 dark:bg-gray-800 rounded-lg">
      <article className="grid gap-8 px-4 py-2 text-left disabled:cursor-auto disabled:dark:text-gray-700 disabled:dark:bg-gray-900/40 dark:bg-gray-900 border-b-1 dark:border-gray-700/70 rounded-lg">
        <h4 className="capitalize text-b1-semi">Total</h4>
        <span className="text-h4 dark:text-blue-500">{data?.total || "-"}</span>
      </article>
      <article className="grid gap-8 px-4 py-2 text-left disabled:cursor-auto disabled:dark:text-gray-700 disabled:dark:bg-gray-900/40 dark:bg-gray-900 border-b-1 dark:border-gray-700/70 rounded-lg">
        <h4 className="capitalize text-b1-semi">Active</h4>
        <span className="text-h4 dark:text-blue-500">
          {data?.active || "-"}
        </span>
      </article>
      <article className="grid gap-8 px-4 py-2 text-left disabled:cursor-auto disabled:dark:text-gray-700 disabled:dark:bg-gray-900/40 dark:bg-gray-900 border-b-1 dark:border-gray-700/70 rounded-lg">
        <h4 className="capitalize text-b1-semi">&lt; 6 Months</h4>
        <span className="text-h4 dark:text-blue-500">{data?.newer || "-"}</span>
      </article>
    </section>
  );
};

export default OverallStats;
