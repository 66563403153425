/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import ModalLayout from "../../../layouts/ModalLayout";
import {
  UpdateRole,
  GetRoleInfo,
  GetAllRoles,
} from "../../../services/settings/roles";
import { Role } from "../../../types/settings";
import { getCustomerID } from "../../../utils/general";
import { initialRoleDetails } from "src/constants/settings";
import RegularInput from "src/components/Input/RegularInput";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EditRole = ({
  role,
  setCustomerID,
}: {
  role: Role;
  setCustomerID: (customerID: string) => void;
}) => {
  const roleID = role.role_id;

  const customerID = getCustomerID();

  const [show, setShow] = useState<boolean>(false);
  const [editRoleDetails, setEditRoleDetails] = useState(initialRoleDetails);
  const [valid, setValid] = useState<boolean>(true);

  const { data: allRoles } = GetAllRoles(customerID);
  const { data: roleInfo } = GetRoleInfo(customerID, roleID);
  const updateRole = UpdateRole(customerID);

  useEffect(() => {
    if (roleInfo) {
      setEditRoleDetails({
        role_name: roleInfo.role_name || "",
        role_type: roleInfo.role_type || "",
      });
    }
  }, [roleInfo]);

  const handleOnClose = () => {
    setValid(true);
    setShow(false);
    setCustomerID("");
    setEditRoleDetails(initialRoleDetails);
  };

  return (
    <>
      <button
        className="absolute right-5 top-1/4 white-text-button"
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="flex flex-col flex-grow gap-4">
          <h3 className="flex items-center gap-2 text-t1-semi">Edit Role</h3>
          <RegularInput
            label="Role Name"
            keyName="role_name"
            inputs={editRoleDetails}
            setInputs={setEditRoleDetails}
            valid={valid}
            setValid={setValid}
            required
          />
          <RegularInput
            label="Role Type"
            keyName="role_type"
            inputs={editRoleDetails}
            setInputs={setEditRoleDetails}
            disabled={true}
          />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={editRoleDetails.role_name === ""}
              className="blue-button"
              onClick={() => {
                if (
                  allRoles?.some(
                    (role: Role) =>
                      role.role_id !== roleID &&
                      role.role_name.toLowerCase().trim() ===
                        editRoleDetails.role_name.toLowerCase().trim()
                  )
                )
                  setValid(false);
                else {
                  handleOnClose();
                  updateRole.mutate({
                    roleID,
                    role: editRoleDetails,
                  });
                }
              }}
            >
              Done
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default EditRole;
