import React, { useState } from "react";
import AuditMetadata from "./AuditMetadata";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import AuditTabs from "./AuditTabs";
import Sections from "./Sections/Sections";
import Filters from "./Sections/Filters";
import Overview from "./Overview/Overview";

const Audit = () => {
  const parsed = parseURL();

  const documentType = "frameworks";
  const documentID = parsed.framework_id ? String(parsed.framework_id) : "";
  const auditID = String(parsed.audit_id) || "";

  const [selectedTab, setSelectedTab] = useState("overview");
  const [filters, setFilters] = useState({
    filter: "All",
    hide_excluded: false,
    analysis_generated: false,
    has_evidence: false,
  });

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-4 px-5 pt-5 h-full w-full overflow-auto scrollbar">
        <AuditMetadata
          documentType={documentType}
          documentID={documentID}
          auditID={auditID}
        />
        <header className="flex items-center gap-4">
          <AuditTabs
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          {selectedTab === "controls" && (
            <Filters
              auditID={auditID}
              filters={filters}
              setFilters={setFilters}
            />
          )}
        </header>
        {selectedTab === "overview" ? (
          <Overview auditID={auditID} />
        ) : selectedTab === "controls" ? (
          <>
            <Sections
              documentType={documentType}
              documentID={documentID}
              auditID={auditID}
              filters={filters}
            />
          </>
        ) : null}
      </main>
    </PageLayout>
  );
};

export default Audit;
