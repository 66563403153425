/* eslint-disable react-hooks/exhaustive-deps */
import { faCheck, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { GetAllUsers } from "src/services/settings/users";
import { KeyStringVal } from "src/types/general";
import { User } from "src/types/settings";
import { getCustomerID, isValidUUID } from "src/utils/general";

const UserInput = ({
  label,
  keyName,
  inputs,
  setInputs,
  allowUnselect,
  required,
  allowNewValue,
  sendToOriginal,
  disabled,
}: {
  label: string;
  keyName: string;
  inputs: any;
  setInputs: (inputs: any) => void;
  allowUnselect?: boolean;
  required?: boolean;
  allowNewValue?: boolean;
  sendToOriginal?: boolean;
  disabled?: boolean;
}) => {
  const customerID = getCustomerID();

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [newValue, setNewValue] = useState<string>("");

  const { data: allUsers } = GetAllUsers(customerID, false);

  const [updatedList, setUpdatedList] = useState<KeyStringVal[]>([]);

  const value = inputs[`${keyName}_text`] || inputs[keyName];
  const filteredUsers = updatedList?.filter((user: KeyStringVal) =>
    user.user_email
      ?.toLowerCase()
      .replace(/\s+/g, "")
      .includes(query.toLowerCase().replace(/\s+/g, ""))
  );
  const userEmail = inputs[`${keyName}_text`]
    ? inputs[`${keyName}_text`]
    : inputs[keyName]
    ? updatedList?.find(
        (user: KeyStringVal) => user.user_id === inputs[keyName]
      )?.user_email
    : "";

  useEffect(() => {
    if (allUsers && updatedList.length === 0) {
      if (inputs[`${keyName}_text`])
        setUpdatedList([
          {
            user_id: inputs[`${keyName}_text`],
            user_name: inputs[`${keyName}_text`],
            user_email: inputs[`${keyName}_text`],
          },
          ...allUsers,
        ]);
      else if (sendToOriginal) {
        if (!isValidUUID(inputs[keyName]))
          setUpdatedList([
            {
              user_id: inputs[keyName],
              user_name: inputs[keyName],
              user_email: inputs[keyName],
            },
            ...allUsers,
          ]);
        else setUpdatedList(allUsers);
      } else setUpdatedList(allUsers);
    }
  }, [allUsers]);

  return (
    <article className="grid content-start text-b2-reg">
      <header className="flex items-center gap-1 justify-self-start">
        <h4>{label}</h4>
        {required && <span className="text-b1-reg text-red-500">*</span>}
      </header>
      <article
        onMouseLeave={() => setShowDropdown(false)}
        onClick={() => setShowDropdown(true)}
        className="relative px-4 py-2 w-full h-9 dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 focus:outline-none rounded"
      >
        <article className="flex items-center justify-between gap-2">
          <input
            type="input"
            disabled={disabled}
            autoComplete="off"
            spellCheck="false"
            placeholder="Select user"
            value={query || userEmail}
            onChange={(e) => {
              if (!showDropdown) setShowDropdown(true);
              setQuery(e.target.value);
            }}
            className="w-full border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
          <span className="flex items-center pointer-events-none">
            <ChevronDownIcon
              className="w-5 h-5 dark:text-gray-500"
              aria-hidden="true"
            />
          </span>
        </article>
        {!disabled && showDropdown && (
          <article className="absolute top-9 left-0 grid gap-1 p-1 content-start w-full max-h-36 dark:bg-gray-900 focus:outline-none shadow dark:shadow-black overflow-auto scrollbar rounded-b-sm z-10">
            {allowNewValue && (
              <article className="flex items-cneter gap-2 m-2">
                <textarea
                  value={newValue}
                  placeholder="Add New User"
                  onChange={(e) => {
                    setNewValue(e.target.value);
                    if (!e.target.value && allowUnselect)
                      setInputs({
                        ...inputs,
                        [keyName]: null,
                        ...(allowNewValue &&
                          !sendToOriginal && {
                            [`${keyName}_text`]: null,
                          }),
                      });
                  }}
                  className="px-4 py-2 w-full h-9 text-b2-reg overflow-hidden border-0 bg-transparent dark:hover:bg-gray-700 duration-100 ring dark:ring-offset-gray-700/50 dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 resize-none rounded"
                />
                <button
                  disabled={!newValue}
                  className="small-blue-button"
                  onClick={() => {
                    setUpdatedList([
                      {
                        user_name: newValue,
                        user_email: newValue,
                        user_id: newValue,
                      },
                      ...updatedList,
                    ]);
                    setNewValue("");
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </article>
            )}
            {filteredUsers?.map((user: User) => {
              const selected = value === user.user_id;
              return (
                <button
                  key={user.user_email}
                  className={`relative flex items-center gap-2 px-4 py-1 break-words dark:hover:bg-gray-800 duration-100 ${
                    userEmail === user.user_email ? "dark:bg-gray-800" : ""
                  } rounded-lg`}
                  onClick={() => {
                    setQuery("");
                    if (allowUnselect && selected) {
                      setInputs({
                        ...inputs,
                        [keyName]: null,
                        ...(allowNewValue && {
                          [`${keyName}_text`]: null,
                        }),
                      });
                    } else
                      setInputs({
                        ...inputs,
                        ...((user.user_id !== user.user_name ||
                          sendToOriginal) && {
                          [keyName]: user.user_id,
                          [`${keyName}_text`]: null,
                        }),
                        ...(allowNewValue &&
                          !sendToOriginal &&
                          user.user_id === user.user_name && {
                            [keyName]: null,
                            [`${keyName}_text`]: user.user_email,
                          }),
                      });
                  }}
                >
                  {selected && <FontAwesomeIcon icon={faCheck} />}
                  <p className="grid text-left">
                    {user.user_email}{" "}
                    <span className="text-b3-reg dark:text-gray-400">
                      {user.user_name}
                    </span>
                  </p>
                </button>
              );
            })}
          </article>
        )}
      </article>
    </article>
  );
};

export default UserInput;
