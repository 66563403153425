import React, { useEffect, useState } from "react";
import RegularInput from "src/components/Input/RegularInput";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import FileInput from "src/components/Input/FileInput";
import { read, utils } from "xlsx";
import { checkIsReadOnly } from "src/utils/general";
import Sheet from "src/components/GRC/FileSheets/Sheet";
import { SubmitDocument } from "src/services/questionnaire-rfp/other-documents";

const UploadDocument = () => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: "",
    file: "",
  });
  const [headers, setHeaders] = useState<any>({});
  const [numOfRows, setNumOfRows] = useState<any>({});
  const [markedAsQuestionCol, setMarkedAsQuestionCol] = useState<any>({});
  const [markedAsAnswerCol, setMarkedAsAnswerCol] = useState<any>({});

  const uploadDocument = SubmitDocument();

  const ignoreResponse = !inputs.file?.name?.includes("xlsx");

  const handleOnClose = () => setShow(false);

  useEffect(() => {
    if (inputs.file) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(inputs.file);
      fileReader.onload = (event) => {
        let data = event.target?.result;
        let workbook = read(data, { type: "binary" });
        const tempHeaders = {} as any;
        workbook.SheetNames.forEach((sheet: string) => {
          const rowObject = utils.sheet_to_json(workbook.Sheets[sheet], {
            header: 1,
            defval: "",
          });
          let curHeaders = [];
          if (numOfRows[sheet] > 1) {
            const maxHeaderRows = Math.max(
              ...(workbook.Sheets[sheet]["!merges"] || [])?.reduce(
                (pV: number[], cV: any) => [...pV, cV.e.r],
                []
              )
            );
            const topCols = rowObject[0] as string[];
            let topColIdx = 0;
            curHeaders = (
              rowObject[Math.max(maxHeaderRows, 2)] as string[]
            )?.map((col, idx) => {
              if (col !== "") return `${topCols[topColIdx]} - ${col}`;
              else {
                topColIdx = idx + 1;
                return topCols[idx];
              }
            });
          } else curHeaders = rowObject[0] as string[];
          tempHeaders[sheet] = curHeaders;
        });
        setHeaders(tempHeaders);
      };
    } else setHeaders({});
  }, [inputs.file, numOfRows]);

  return (
    <>
      <button
        disabled={isReadOnly}
        className="place-content-center blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            file: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faUpload} />
        <h4>Upload Document</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Upload Document
          </h3>
          <RegularInput
            label="Name (optional)"
            keyName="name"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <FileInput
            label="Document"
            keyName="file"
            types={["pdf", "docx", "xlsx"]}
            inputs={inputs}
            setInputs={setInputs}
          />
          {!ignoreResponse && Object.keys(headers).length > 0 && (
            <section className="grid gap-4 overflow-auto scrollbar">
              {Object.keys(headers).map((sheet, index) => {
                return (
                  <Sheet
                    key={index}
                    headers={headers}
                    sheet={sheet}
                    numOfRows={numOfRows}
                    setNumOfRows={setNumOfRows}
                    markedAsQuestionCol={markedAsQuestionCol}
                    setMarkedAsQuestionCol={setMarkedAsQuestionCol}
                    markedAsAnswerCol={markedAsAnswerCol}
                    setMarkedAsAnswerCol={setMarkedAsAnswerCol}
                    ignoreResponse
                  />
                );
              })}
            </section>
          )}
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={
                inputs.file === "" ||
                (!ignoreResponse &&
                  Object.keys(markedAsQuestionCol).length === 0)
              }
              className="blue-button"
              onClick={() => {
                const formData = new FormData();

                if (inputs.name !== "") formData.append("name", inputs.name);
                if (inputs.file) formData.append("file", inputs.file);

                let toExtract = {} as any;
                Object.keys(headers).forEach((sheet, sheetIndex: number) => {
                  if (
                    markedAsQuestionCol.hasOwnProperty(sheet) &&
                    numOfRows.hasOwnProperty(sheet)
                  )
                    toExtract[sheetIndex] = {
                      questions: markedAsQuestionCol[sheet],
                      num_header_rows: numOfRows[sheet],
                    };
                });
                formData.append("to_extract", JSON.stringify(toExtract));

                uploadDocument.mutate({
                  formData,
                });
                handleOnClose();
              }}
            >
              <h4>Submit</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default UploadDocument;
