import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { SendEmailToVendor } from "../../../../../services/third-party-risk/vendors/vendors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { checkIsReadOnly } from "src/utils/general";

const SendEmail = ({
  vendorID,
  assessmentID,
  vendor,
}: {
  vendorID: string;
  assessmentID?: string;
  vendor: any;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [valid, setValid] = useState<boolean>(true);

  const sendEmail = SendEmailToVendor(vendorID, assessmentID);

  const inherentRiskAssessment = sessionStorage.assessmentCycle
    ?.toLowerCase()
    ?.includes("inherent risk");
  const label = `Email ${inherentRiskAssessment ? "Manager" : "Vendor"}`;
  const disabled =
    isReadOnly ||
    sendEmail.status === "loading" ||
    (!inherentRiskAssessment && vendor.contacts?.length === 0) ||
    (inherentRiskAssessment && vendor.vendor_manager?.length === 0);

  const handleOnClose = () => {
    setValid(true);
    sendEmail.reset();
  };

  return (
    <>
      <button
        disabled={disabled}
        className="blue-button"
        onClick={() => {
          if (
            vendor.contacts?.length > 0 ||
            vendor.vendor_manager?.length > 0
          ) {
            setValid(true);
            sendEmail.mutate({});
          } else setValid(false);
        }}
      >
        {label}
      </button>
      <ModalLayout showModal={!valid} onClose={handleOnClose}>
        <section className="grid gap-4">
          <h2 className="text-subt1-semi">{label}</h2>
          <p className="flex items-center gap-2 text-b1-reg">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="text-red-700"
            />{" "}
            A valid email must be present to send email to vendor.
          </p>
          <button
            className="place-self-end blue-button"
            onClick={handleOnClose}
          >
            Close
          </button>
        </section>
      </ModalLayout>
      <ModalLayout
        showModal={sendEmail.status === "success"}
        onClose={handleOnClose}
      >
        <section className="grid gap-4">
          <h2 className="text-subt1-semi">Send Email to Vendor</h2>
          <p className="text-b1-reg">
            An email with the one-time access code has been sent out. The access
            code will expire in{" "}
            {sendEmail.data?.expiry_time <= 24
              ? `${sendEmail.data?.expiry_time} hours`
              : `${Math.round(sendEmail.data?.expiry_time / 24)} days`}
            , so please make sure to click through in time.
          </p>
          <button
            className="place-self-end blue-button"
            onClick={handleOnClose}
          >
            Got it!
          </button>
        </section>
      </ModalLayout>
    </>
  );
};

export default SendEmail;
