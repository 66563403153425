/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  FindSimilarControls,
  GetControlMetadata,
  GetFrameworkmappingCount,
  GetSimilarControlsStatus,
} from "src/services/erc/controls/controls";
import ToastLayout from "src/layouts/ToastLayout";
import {
  faChevronRight,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LineWave } from "react-loader-spinner";
import { handleClickMapping } from "src/utils/grc";
import { checkIsReadOnly } from "src/utils/general";

const SimilarControls = ({ controlID }: { controlID: string }) => {
  const isReadOnly = checkIsReadOnly();

  const { data: metadata } = GetControlMetadata(controlID);
  const findSimilarControls = FindSimilarControls(controlID);
  const { data: similarControlStatus } = GetSimilarControlsStatus(controlID);
  const { data: count } = GetFrameworkmappingCount(
    controlID,
    similarControlStatus?.status
  );

  const handleOnCloseConfirmation = () => findSimilarControls.reset();

  return (
    <section className="flex flex-col flex-gcontrol gap-4 p-6 dark:bg-gray-800 rounded-2xl">
      <header className="flex items-center gap-4">
        <h4 className="text-t1-semi">Similar Controls</h4>
        {similarControlStatus?.status === "PROCESSING" ? (
          <span className="flex items-center gap-1">
            Generating
            <LineWave
              visible={true}
              height="30"
              width="30"
              color="#4fa94d"
              ariaLabel="line-wave-loading"
              wrapperStyle={{}}
              wrapperClass=""
              firstLineColor=""
              middleLineColor=""
              lastLineColor=""
            />
          </span>
        ) : similarControlStatus?.status === "FAILED" ? (
          <span>
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="text-red-500"
            />{" "}
            Generation failed
          </span>
        ) : similarControlStatus?.status === "NONE" ? (
          <button
            disabled={isReadOnly}
            className="blue-button"
            onClick={() => {
              findSimilarControls.mutate({
                inputs: {
                  framework_name_list: [],
                },
              });
            }}
          >
            Find Similar Controls
          </button>
        ) : similarControlStatus?.status === "READY" && metadata && count ? (
          <a
            href="/grc/mapping"
            className="blue-text-button"
            onClick={(e) => {
              e.stopPropagation();

              handleClickMapping(
                "controls",
                metadata.name,
                metadata.control_id,
                "",
                metadata,
                "RFS",
                count?.mapping_framework_count,
                "",
                metadata.control_id
              );
            }}
          >
            <FontAwesomeIcon icon={faChevronRight} />
            <p>{count?.mapping_framework_count} Mapped</p>
          </a>
        ) : null}
      </header>
      <ToastLayout
        showModal={findSimilarControls.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="GRC Agent has triggered the generation and you will see a notification"
      />
    </section>
  );
};

export default SimilarControls;
